import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { endpoint } from "./SecondPage";
import React, { useEffect } from "react";

export const FirstPage = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const disableBackArrow = () => {
      window.history.pushState(null, null, window.location.pathname);
    };

    disableBackArrow();

    return () => {
      window.removeEventListener("popstate", disableBackArrow);
    };
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (name != "") {
      const result = await axios.get(endpoint + `/get-qrcodes?name=${name}`);

      if (result.data != "User Not Exist") {
        if (result.data.used) {
          // qr was used
          toast.error("الرجاء إدخال رمز صالح", {
            position: "top-center",
          });
          setName("");
        } else {
          // qr was NOT used
          toast.success("رمز التسجيل صالح", {
            position: "top-center",
          });

          setTimeout(() => {
            navigate("/second", {
              state: { data: result.data._id, name: result.data.name },
            });
          }, 1000);
        }
      } else if (result.data == "User Not Exist") {
        toast.error("الرجاء إدخال رمز صالح", {
          position: "top-center",
        });

        setName("");
      }
    } else {
      toast.error("الرجاء إدخال رمز صالح", {
        position: "top-center",
      });
      setName("");
    }
  };

  return (
    <div
      className="my-0 py-5 d-flex align-items-center"
      style={{
        backgroundImage: "url(logonew10.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-4">
            <div
              className="my-5 cascading-right"
              style={{
                background: "hsla(0, 0%, 100%, 0.55)",
                backdropFilter: "blur(30px)",
                padding: "3rem",
                borderRadius: "15px",
              }}
            >
              <h2
                className="fw-bold mb-5 text-center"
                style={{
                  fontSize: "1.5rem", // Adjust the fontSize value to change the size
                }}
              >
                نموذج رمز التفعيل
              </h2>
              <form>
                <div className="mb-4">
                  <label
                    htmlFor="form3"
                    className="form-label"
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    ادخل رمزك
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form3"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      fontSize: "1.1rem", // Adjust the fontSize value to change the size
                      padding: "0.5rem", // Adjust the padding value to change the size
                    }}
                  />
                </div>

                <button
                  onClick={handleOnSubmit}
                  className="w-100 mb-4 btn btn-warning"
                  color="#A28F69"
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  تسجيل
                </button>
              </form>

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
