// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';

const App = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<FirstPage/>}/>
          <Route exact path="/second" element={<SecondPage/>}/> 
        </Routes>
    </Router>
  );
};

export default App;

