import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const endpoint = "https://shehabqr.cloud.ps/test3/backend";

const cities = [
  "أبو ديس",
  "البيرة",
  "بيت جالا",
  "بيت ساحور",
  "بيتونيا",
  "بيت لحم",
  "دورا",
  "الخليل",
  "جنين",
  "أريحا",
  "كفر قدوم",
  "نابلس",
  "قلقيلية",
  "رام الله",
  "سلفيت",
  "سلواد",
  "سردا",
  "طولكرم",
  "طوباس",
  "يطا",
];
const SecondPage = () => {
  const [name, setName] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCityOption] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state ? location.state.data : null;

  const code = location.state ? location.state.name : null;
  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (data != "" || data != null) {
      if (name && mobile && address && city && code) {
        let result = await fetch(endpoint + "/register-user", {
          method: "post",
          body: JSON.stringify({ name, mobile, address, city, code }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (result.status != 500) {
          toast.success("تم التسجيل بنجاح", {
            position: "top-center",
          });

          // we need to add to QR used when it was succsessflly

          let resultNew = await fetch(endpoint + "/edit-qrcodes", {
            method: "post",
            body: JSON.stringify({ data }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          console.warn(resultNew);

          setName("");
          setMobileNumber("");
          setAddress("");
          setCityOption("");
          //    data = "";
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error("الاسم موجود مسبقا", {
            position: "top-center",
          });
          setName("");
          setMobileNumber("");
          setAddress("");
          setCityOption("");
        }
      } else {
        toast.error(" الرجاء إدخال الاسم والجوال والعنوان والمدينة", {
          position: "top-center",
        });
        setName("");
        setMobileNumber("");
        setAddress("");
        setCityOption("");
      }
    } else {
      toast.error("الرجاء إدخال رمز صالح", {
        position: "top-center",
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };

  if (data == null || data == "") {
    return (
      <div className="container">
        <h1 className="title">404 الصفحة غير موجودة</h1>
        <p className="subtitle">
          المعذرة ، الصفحة التي طلبتها لا يمكن العثور عليها
        </p>
        <style jsx>{`
          .container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
          }
          .title {
            font-size: 3rem;
            text-align: center;
            margin-bottom: 20px;
          }
          .subtitle {
            font-size: 2rem;
            text-align: center;
          }
          @media only screen and (max-width: 768px) {
            .title {
              font-size: 2rem;
            }
            .subtitle {
              font-size: 1.5rem;
            }
          }
          @media only screen and (max-width: 480px) {
            .title {
              font-size: 1.5rem;
            }
            .subtitle {
              font-size: 1rem;
            }
          }
        `}</style>
      </div>
    );
  } else {
    return (
      <div
        className="my-0 py-5 d-flex align-items-center"
        style={{
          backgroundImage: "url(logonew10.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-4">
              <div
                className="my-5 cascading-right"
                style={{
                  background: "hsla(0, 0%, 100%, 0.55)",
                  backdropFilter: "blur(30px)",
                  padding: "2rem",
                  borderRadius: "10px",
                }}
              >
                <h2
                  className="fw-bold mb-5 text-center"
                  style={{
                    fontSize: "1.5rem", // Adjust the fontSize value to change the size
                  }}
                >
                  نموذج التسجيل
                </h2>
                <form>
                  <div className="mb-4">
                    <label
                      htmlFor="form3"
                      className="form-label"
                      style={{
                        fontSize: "1.1rem",
                      }}
                    >
                      الاسم
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="form3"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        fontSize: "1.1rem", // Adjust the fontSize value to change the size
                        padding: "0.5rem", // Adjust the padding value to change the size
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="form3"
                      className="form-label"
                      style={{
                        fontSize: "1.1rem",
                        padding: "0.5rem", // Adjust the padding value to change the size
                      }}
                    >
                      رقم الهاتف
                    </label>
                    <input
                      type="tel"
                      pattern="[0-9]*"
                      className="form-control"
                      id="form3"
                      value={mobile}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      style={{
                        fontSize: "1.2rem", // Adjust the fontSize value to change the size
                        padding: "0.5rem", // Adjust the padding value to change the size
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="form3"
                      className="form-label"
                      style={{
                        fontSize: "1.1rem",
                        padding: "0.5rem", // Adjust the padding value to change the size
                      }}
                    >
                      العنوان
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="form3"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{
                        fontSize: "1.2rem", // Adjust the fontSize value to change the size
                        padding: "0.5rem", // Adjust the padding value to change the size
                      }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="dropdown"
                      className="form-label"
                      style={{ fontSize: "1.1rem", padding: "0.5rem" }}
                    >
                      اختيار المدينة
                    </label>
                    <select
                      id="dropdown"
                      value={city}
                      onChange={(e) => setCityOption(e.target.value)}
                      className="form-control"
                      style={{ fontSize: "1.1rem", padding: "0.5rem" }}
                    >
                      <option value="">-- اختر --</option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br></br>
                  <button
                    onClick={handleOnSubmit}
                    className="w-100 mb-4 btn btn-warning"
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    تسجيل
                  </button>
                </form>

                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default SecondPage;
